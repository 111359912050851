<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-17 14:27:31
 * @LastEditTime: 2020-12-17 15:11:23
-->
<template>
  <div class="record h-100">
    <div class="record_list" v-if="recordData != [] && recordData.length > 0">
      <div
        class="list"
        v-for="(item, index) in recordData"
        :key="index"
        @click="goDetail(item.businessVisitorId)"
      >
        <img
          class="img"
          :src="`${publickPath}static/img/icons/wybl.png`"
          alt=""
        />
        <div class="right">
          <div class="title title1 van-multi-ellipsis--l1">
            {{ item.spmc }}
          </div>
          <div class="title title2">
            登记购买{{ item.goodsTypePOList.length }}件商品
          </div>
          <div class="title title3">登记时间：{{ item.visitorTime1 }}</div>
        </div>
      </div>
    </div>
    <div class="noList" v-else>
      <img
        class="img1"
        :src="`${publickPath}static/img/icons/icon-goods-record.png`"
      />
      <p class="p1">当前无相关记录</p>

      <u-button
        @click="toIndex"
        size="large"
        style="
          position: fixed;
          left: 50%;
          transform: translateX(-50%);
          bottom: 2rem;
        "
        type="primary"
        >返回首页</u-button
      >
    </div>
  </div>
</template>

<script>
import { businessBusinessVisitorList } from "@utils/aRequest";
import { mapState, mapGetters } from "vuex";
import UButton from "@/components/u-button";
export default {
  data() {
    return {
      publickPath: process.env.BASE_URL,
      recordData: [],
    };
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["returnRoleData"]),
  },
  components: {
    UButton,
  },
  created() {
    let roleData = this.returnRoleData;
    let userId = roleData.userPO.id;
    if (userId) {
      this.getInitialization(userId);
    }
  },

  watch: {},
  methods: {
    getInitialization(userId) {
      var self = this;
      businessBusinessVisitorList({ userId: userId }).then((data) => {
        if (data.code == "SUCCESS") {
          // console.log(data);
          let da = data.data;
          da.forEach((ele) => {
            ele.visitorTime1 = this.$tools.format(ele.visitorTime);
          });
          self.recordData = da;
        }
      });
    },
    toIndex() {
      this.$router.push({ path: "/main" });
    },
    goDetail(id) {
      console.log(id);
      this.$router.push({ path: "/goods/BuyRecordDetail", query: { id: id } });
    },
  },
  mounted() {
    this.$nextTick(() => {});
  },
};
</script>

<style lang="less" scoped>
.record {
  text-align: center;
  position: relative;
  // padding: 0 1.0666rem; /* 90/18.75 */
  .record_list {
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
    overflow-y: auto;
    padding-bottom: 3rem;
    .list {
      width: 100%;
      padding: 1.2rem 1rem;
      background-color: #fff;
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 10px;
      }
      .right {
        flex: 1;
        margin-left: 1rem;
        .title {
          margin-bottom: 0.4rem;
          text-align: left;
        }
        .title1 {
          font-size: 1rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }
        .title2 {
          font-size: 0.8rem;
          font-family: PingFang SC;
          color: #666666;
        }
        .title3 {
          font-size: 0.6rem;
          font-family: PingFang SC;
          color: #b5b5b5;
        }
      }
    }
  }
  .noList {
    width: 100%;
    height: 100%;
    .img1 {
      width: 12rem;
      margin-top: 5rem;
      margin-bottom: 1rem;
    }
    .p1 {
      font-size: 0.8rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
}
</style>