<!--
 * @Descripttion:
 * @Author: NoYo
 * @Date: 2020-12-15 15:22:47
 * @LastEditTime: 2020-12-17 14:59:22
-->
<template>
  <button class="u-button"
          :class="getClass()"
          :disabled="disabled"
          @click="uClick">
    <slot>
    </slot>
  </button>
</template>

<script>
export default {
  name: '',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'normal'
    },
    plain: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
    }
  },
  components: {
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    getClass () {
      let className = ''
      className += this.type + ' '
      className += this.size + ' '
      className += this.plain ? 'plain' : ''
      return className
    },
    uClick (e) {
      // 取消默认行为 防止冲突
      e.preventDefault();
      this.$emit('click')
    }
  },
}
</script>

<style lang="less" scoped>
.u-button {
  outline: none;
  border: 0.053333rem /* 1/18.75 */ solid #e1dddd;
  color: #fff;
  font-family: PingFang SC;
  padding: 0;
  text-align: center;
  transition: 0.2s;
  display: block;
  &:disabled {
    background: #e6e6e6 !important;
    cursor: not-allowed;
  }
  &.small {
    width: 5.6rem /* 105/18.75 */;
    height: 1.813333rem /* 34/18.75 */;
    line-height: 1.706667rem /* 32/18.75 */;
    border-radius: 0.213333rem /* 4/18.75 */;
    font-size: 0.8rem /* 15/18.75 */;
    font-family: PingFang SC;
    font-weight: 400;
  }
  &.large {
    width: calc(100% - 2.133333rem /* 40/18.75 */);
    margin: 0 auto;
    height: 2.4rem /* 45/18.75 */;
    line-height: 2.293333rem /* 43/18.75 */;
    border-radius: 0.373333rem /* 7/18.75 */;
    font-size: 0.96rem /* 18/18.75 */;
    font-weight: 400;
  }
  &.primary {
    background: #2b71fd;
    &.plain {
      background: #fff;
      color: #2b71fd;
      border: 1px solid #2b71fd;
    }
  }
}
</style>